import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store';
import { DefaultRes } from '../types';

export const ENVS = {
    dev: {
        BASE_URL: 'http://localhost:5000/v1',
    },
    stg: {
        BASE_URL: 'http://localhost:3000/v1',
    },
    prd: {
        BASE_URL: 'https://api.zapfy.me/v1',
        MILVUS_URL: 'https://milvus.zapfy.me/v1',
        YOOGA_URL: 'https://yooga.zapfy.me/v1',
        BOMCONTROLE_URL: 'https://bomcontrole.zapfy.me/v1',
        MF_URL: 'https://mfrural.zapfy.me/v1',
        MEGA_01_URL: 'https://megasender01.zapfy.me/v1/',
        MEGA_02_URL: 'https://megasender02.zapfy.me/v1/',
    },
};

export const api = axios.create({
    baseURL: ENVS.prd.BASE_URL,
});

export const apiMilvus = axios.create({
    baseURL: ENVS.prd.MILVUS_URL,
});

export const apiYooga = axios.create({
    baseURL: ENVS.prd.YOOGA_URL,
});

export const apiBc = axios.create({
    baseURL: ENVS.prd.BOMCONTROLE_URL,
});

export const apiMf = axios.create({
    baseURL: ENVS.prd.MF_URL
});

export const apiMega01 = axios.create({
    baseURL: ENVS.prd.MEGA_01_URL
});

export const apiMega02 = axios.create({
    baseURL: ENVS.prd.MEGA_02_URL
});

const onRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
    return {
        ...axiosConfig,
        headers: {
            'authorization': `${store.getState().user.token}`,
        }
    };
};
  
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError<DefaultRes>): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<DefaultRes>) => {
    return response;
};
// @ts-ignore
api.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
api.interceptors.response.use(onResponse, onResponseError);

apiMilvus.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
apiMilvus.interceptors.response.use(onResponse, onResponseError);

apiMf.interceptors.request.use(onRequest, onRequestError);
apiMf.interceptors.response.use(onResponse, onResponseError);

apiMega01.interceptors.request.use(onRequest, onRequestError);
apiMega01.interceptors.response.use(onResponse, onResponseError);

apiMega02.interceptors.request.use(onRequest, onRequestError);
apiMega02.interceptors.response.use(onResponse, onResponseError);