import { AxiosInstance } from "axios";

import { api, apiMilvus, apiYooga, apiBc, apiMf, apiMega01, apiMega02 } from "../http";

export const intanceSourceResolver: Record<string, AxiosInstance> = {
    core: api,
    milvus: apiMilvus,
    yooga: apiYooga,
    bomcontrole: apiBc,
    mf: apiMf,
    megasender01: apiMega01,
    megasender02: apiMega02
}